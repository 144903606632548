import React from 'react';
import { Helmet } from 'react-helmet';

const StructuredData = () => {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "VitalPath Solutions",
    "description": "AI-powered health coaching for chronic disease management",
    "url": "https://www.vitalpathsolutions.com",
    "logo": "https://www.vitalpathsolutions.com/logo.png",
    "sameAs": [
      "https://www.facebook.com/vitalpathsolutions",
      "https://www.linkedin.com/company/vitalpath-solutions",
      "https://twitter.com/vitalpathAI"
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default StructuredData;