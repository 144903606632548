import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const InvestorPartnerPage = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const sections = [
    {
      title: "Revolutionary Solution",
      content: "VitalPath Solutions harnesses the power of AI and cloud computing to deliver personalized, empathetic health coaching at an unprecedented scale. Our platform meets the growing demand for personalized, preventive health care while reducing the cost of chronic disease management.",
      image: "/images/revolutionary-solution.jpg",
      details: [
        "AI-powered personalized health coaching",
        "Scalable chronic disease management",
        "Proactive and preventive care approach",
        "Improved patient outcomes and engagement"
      ]
    },
    {
      title: "Market Potential",
      content: "The digital health market is experiencing rapid growth, with chronic diseases driving 86% of healthcare costs. Our AI-driven solution addresses this massive market, creating unprecedented opportunities for innovative health management.",
      image: "/images/market-potential.jpg",
      details: [
        "70% of deaths in the US are due to chronic diseases",
        "86% of healthcare costs driven by chronic conditions",
        "Rapidly growing digital health market",
        "Increasing demand for AI-powered health solutions"
      ]
    },
    {
      title: "Competitive Advantage",
      content: "Our solution stands out with unparalleled personalization, scalability without compromise, and continuous improvement. We offer seamless integration with existing health IT systems, ensuring comprehensive care coordination.",
      image: "/images/competitive-advantage.jpg",
      details: [
        "Unique AI-driven personalization",
        "Scalability from 100 to 1 million users",
        "Adaptive learning for continuous improvement",
        "Seamless integration with existing systems"
      ]
    },
    {
      title: "Strategic Partnerships",
      content: "We're seeking forward-thinking partners who share our vision of a healthier world. Our platform offers significant benefits for various stakeholders in the healthcare ecosystem.",
      image: "/images/strategic-partnerships.jpg",
      details: [
        "Healthcare Providers: Extend reach, improve outcomes",
        "Insurance Companies: Lower claims costs",
        "Wellness Programs: Boost employee health",
        "Large Health Organizations: Enhance care delivery"
      ]
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.h2 
        className="text-3xl font-bold mb-8 text-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Join the Health Coaching Revolution
      </motion.h2>
      
      <div className="grid md:grid-cols-2 gap-6">
        {sections.map((section, index) => (
          <motion.section 
            key={index}
            className="bg-white rounded-lg shadow-md overflow-hidden"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <motion.div 
              className="flex items-center p-4 cursor-pointer"
              onClick={() => setExpandedSection(expandedSection === index ? null : index)}
              whileHover={{ backgroundColor: "#f0f0f0" }}
            >
              <img 
                src={section.image} 
                alt={section.title} 
                className="w-24 h-24 object-cover rounded-full mr-4"
              />
              <h3 className="text-xl font-semibold">{section.title}</h3>
            </motion.div>
            
            <AnimatePresence>
              {expandedSection === index && (
                <motion.div 
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="px-4 pb-4"
                >
                  <p className="mb-4 text-gray-700 leading-relaxed">{section.content}</p>
                  <h4 className="text-lg font-semibold mb-2">Key Points:</h4>
                  <ul className="list-disc pl-5">
                    {section.details.map((detail, detailIndex) => (
                      <motion.li 
                        key={detailIndex}
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: detailIndex * 0.1 }}
                        className="mb-2 text-gray-600"
                      >
                        {detail}
                      </motion.li>
                    ))}
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.section>
        ))}
      </div>
    </div>
  );
}

export default InvestorPartnerPage;