import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import OverviewPage from './pages/OverviewPage';
import InvestorPartnerPage from './pages/InvestorPartnerPage';
import TechnologyPage from './pages/TechnologyPage';
import ApplicationsPage from './pages/ApplicationsPage';
import ContactPage from './pages/ContactPage';
import SEO from './components/SEO';
import StructuredData from './components/StructuredData';

const appStyle = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  fontFamily: 'Arial, sans-serif',
};

const mainStyle = {
  flex: 1,
  padding: '40px 20px',
  backgroundColor: '#f0f0f0',
};

function App() {
  const baseUrl = "https://www.vitalpathsolutions.com"; // Replace with your actual domain

  return (
    <Router>
      <div style={appStyle}>
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <StructuredData />
        <Header />
        <main style={mainStyle}>
          <Routes>
            <Route path="/" element={
              <SEO 
                title="AI-Powered Health Coaching"
                description="VitalPath Solutions offers revolutionary AI-powered health coaching, redefining chronic disease management and prevention."
                keywords="AI health coach, chronic disease management, personalized health coaching, healthcare innovation"
                url={`${baseUrl}/`}
              >
                <HomePage />
              </SEO>
            } />
            <Route path="/overview" element={
              <SEO
                title="Overview of VitalPath Solutions"
                description="Learn about VitalPath Solutions' innovative approach to AI-driven health coaching and chronic disease management."
                keywords="health coaching overview, AI healthcare, chronic disease prevention"
                url={`${baseUrl}/overview`}
              >
                <OverviewPage />
              </SEO>
            } />
            <Route path="/investors-partners" element={
              <SEO
                title="Investors and Partners"
                description="Discover partnership opportunities with VitalPath Solutions and join us in revolutionizing healthcare through AI."
                keywords="healthcare investment, AI partnerships, health tech collaboration"
                url={`${baseUrl}/investors-partners`}
              >
                <InvestorPartnerPage />
              </SEO>
            } />
            <Route path="/technology" element={
              <SEO
                title="Our AI Health Coaching Technology"
                description="Explore the cutting-edge AI technology behind VitalPath Solutions' personalized health coaching platform."
                keywords="AI health technology, machine learning healthcare, health data analytics"
                url={`${baseUrl}/technology`}
              >
                <TechnologyPage />
              </SEO>
            } />
            <Route path="/applications" element={
              <SEO
                title="Applications of AI Health Coaching"
                description="Discover how VitalPath Solutions' AI health coaching can be applied across various healthcare sectors and scenarios."
                keywords="AI health applications, healthcare solutions, personalized medicine"
                url={`${baseUrl}/applications`}
              >
                <ApplicationsPage />
              </SEO>
            } />
            <Route path="/contact" element={
              <SEO
                title="Contact VitalPath Solutions"
                description="Get in touch with VitalPath Solutions to learn more about our AI-powered health coaching platform or discuss partnership opportunities."
                keywords="contact healthcare AI, health tech inquiry, partnership opportunities"
                url={`${baseUrl}/contact`}
              >
                <ContactPage />
              </SEO>
            } />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;