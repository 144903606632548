import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ApplicationsPage = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const applications = [
    {
      title: "For Healthcare Providers",
      content: "VitalPath Solutions empowers healthcare providers to extend their reach and improve patient outcomes. Our AI-powered platform enhances patient care, reduces readmissions, and supports ongoing management through personalized, scalable health coaching.",
      benefits: [
        "Extend reach to more patients without compromising care quality",
        "Significantly reduce hospital readmissions",
        "Improve long-term patient outcomes through continuous engagement",
        "Gain data-driven insights for more effective treatment plans",
        "Seamlessly integrate with existing health IT systems"
      ],
      image: "/images/healthcare-providers.jpg"
    },
    {
        title: "For Insurance Companies",
        content: "Our platform helps insurance companies address the rising costs of chronic disease management through preventive strategies. By encouraging healthier behaviors and providing personalized coaching, we aim to improve overall member health outcomes, which can lead to reduced claims over time.",
        benefits: [
            "Offer value-added services to members, potentially reducing long-term health expenses",
            "Provide tools for members to better manage their health, which may lead to fewer claims",
            "Increase member satisfaction with access to personalized health coaching",
            "Promote preventive care and wellness initiatives",
            "Gain insights from aggregated, anonymized usage data to inform wellness programs"
      ],
      image: "/images/insurance-companies.jpg"
    },
    {
      title: "For Employers and Wellness Programs",
      content: "Large employers can revolutionize their wellness programs with our AI health coach. By offering personalized coaching to every employee, we help boost health, productivity, and satisfaction, creating a win-win situation for both employers and employees.",
      benefits: [
        "Increase employee productivity through improved health",
        "Reduce healthcare expenses and absenteeism",
        "Enhance employee satisfaction and retention",
        "Implement data-driven wellness initiatives",
        "Offer scalable, personalized coaching to all employees"
      ],
      image: "/images/employers-wellness.jpg"
    },
    {
      title: "For Individuals",
      content: "VitalPath Solutions brings expert health coaching to everyone, anytime, anywhere. Our AI-powered platform provides personalized guidance, empowering individuals to take control of their health and make lasting improvements.",
      benefits: [
        "Access expert health coaching 24/7",
        "Receive personalized guidance tailored to your unique needs",
        "Track progress and stay motivated with adaptive goal-setting",
        "Integrate with wearable devices for comprehensive health monitoring",
        "Enjoy a user-friendly interface designed for engagement and results"
      ],
      image: "/images/individuals.jpg"
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.h2
        className="text-3xl font-bold mb-8 text-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Revolutionizing Health Across Sectors
      </motion.h2>
     
      {applications.map((app, index) => (
        <motion.section
          key={index}
          className="mb-8 bg-white rounded-lg shadow-md overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.2 }}
        >
          <motion.div
            className="flex items-center p-4 cursor-pointer"
            onClick={() => setExpandedSection(expandedSection === index ? null : index)}
            whileHover={{ backgroundColor: "#f0f0f0" }}
          >
            <img 
              src={app.image} 
              alt={app.title} 
              className="w-24 h-24 object-cover rounded-full mr-4"
            />
            <h3 className="text-2xl font-semibold">{app.title}</h3>
          </motion.div>
         
          <AnimatePresence>
            {expandedSection === index && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="px-4 pb-4"
              >
                <p className="mb-4">{app.content}</p>
                <h4 className="text-xl font-semibold mb-2">Key Benefits:</h4>
                <ul className="list-disc pl-5">
                  {app.benefits.map((benefit, benefitIndex) => (
                    <motion.li
                      key={benefitIndex}
                      initial={{ opacity: 0, x: 50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.3, delay: benefitIndex * 0.1 }}
                    >
                      {benefit}
                    </motion.li>
                  ))}
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.section>
      ))}
    </div>
  );
}

export default ApplicationsPage;