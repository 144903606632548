import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, Link } from 'react-router-dom';

const HomePage = () => {
  const [activeSection, setActiveSection] = useState(null);
  const navigate = useNavigate();

  const heroStyle = {
    backgroundColor: '#3498db',
    color: 'white',
    padding: '20px',
  };

  const heroContentStyle = {
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const logoStyle = {
    maxWidth: '150px',
    marginRight: 'auto',
    marginBottom: '20px',
  };

  const h2Style = {
    fontSize: '36px',
    marginBottom: '20px',
    textAlign: 'center',
  };

  const pStyle = {
    fontSize: '18px',
    maxWidth: '800px',
    margin: '0 auto 30px',
    textAlign: 'center',
  };

  const buttonStyle = {
    backgroundColor: '#e74c3c',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const contentStyle = {
    maxWidth: '1200px',
    margin: '40px auto',
    padding: '0 20px',
  };

  const sectionStyle = {
    backgroundColor: 'white',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    cursor: 'pointer',
  };

  const handleJoinRevolution = () => {
    navigate('/contact');
  };

  const sections = [
    {
      title: 'Our Vision',
      content: `Imagine a world where personalized health coaching is available to everyone, anytime, anywhere. 
                A world where chronic diseases are managed proactively, not reactively. A world where technology 
                and human touch work in perfect harmony to create lasting health improvements. This isn't a distant 
                dream. It's happening now, and VitalPath Solutions is leading the charge.`
    },
    {
      title: 'The Challenge',
      content: `Chronic diseases are the silent epidemic of our time. They account for 70% of all deaths in the 
                United States, drive 86% of our nation's healthcare costs, and affect millions, diminishing quality 
                of life and productivity. Traditional health coaching works, but it's limited in reach and scale. 
                Until now.`
    },
    {
      title: 'Our Revolutionary Solution',
      content: `VitalPath Solutions harnesses the power of artificial intelligence and cloud computing to deliver 
                personalized, empathetic health coaching at an unprecedented scale. Our AI doesn't just respond – 
                it understands, adapts, and grows with each user. We offer unparalleled personalization, scalability 
                without compromise, engagement that lasts, seamless integration with existing health IT systems, and 
                continuous improvement.`
    },
    {
      title: 'The Impact',
      content: `For Healthcare Providers: Extend your reach, improve patient outcomes, and reduce readmissions. 
                For Wellness Programs: Offer personalized coaching to every employee, boosting health and productivity. 
                For Insurance Companies: Lower claims costs by promoting preventive care and healthier behaviors. 
                For Individuals: Access expert health coaching anytime, anywhere, tailored specifically to you.`
    }
  ];

  return (
    <div>
      <motion.section
        style={heroStyle}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div style={heroContentStyle}>
          <Link to="/">
            <motion.img
              src="/images/vitalpath-logo.png" // Replace with your actual logo path
              alt="VitalPath Solutions Logo"
              style={logoStyle}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          </Link>
          <h2 style={h2Style}>AI Health Coach Solutions: Redefining Chronic Disease Management</h2>
          <p style={pStyle}>
            Welcome to VitalPath Solutions, where we're not just changing the game – we're creating an entirely new playing field in chronic disease management and prevention.
          </p>
          <motion.button
            style={buttonStyle}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleJoinRevolution}
          >
            Join the Revolution
          </motion.button>
        </div>
      </motion.section>
     
      <section style={contentStyle}>
        {sections.map((section, index) => (
          <motion.div
            key={index}
            style={sectionStyle}
            onClick={() => setActiveSection(activeSection === index ? null : index)}
            whileHover={{ scale: 1.02 }}
          >
            <h3>{section.title}</h3>
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{
                height: activeSection === index ? 'auto' : 0,
                opacity: activeSection === index ? 1 : 0
              }}
              transition={{ duration: 0.3 }}
            >
              <p>{section.content}</p>
            </motion.div>
          </motion.div>
        ))}
      </section>
    </div>
  );
}

export default HomePage;