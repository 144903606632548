import React from 'react';

const footerStyle = {
  backgroundColor: '#34495e',
  color: 'white',
  padding: '20px',
  textAlign: 'center',
};

function Footer() {
  return (
    <footer style={footerStyle}>
      <p>&copy; 2024 VitalPath Innovations. All rights reserved.</p>
    </footer>
  );
}

export default Footer;