import React from 'react';
import { Link } from 'react-router-dom';

const headerStyle = {
  backgroundColor: '#2c3e50',
  padding: '20px',
  color: 'white',
};

const navStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const logoStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  margin: 0,
};

const ulStyle = {
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
};

const liStyle = {
  marginLeft: '20px',
};

const linkStyle = {
  color: 'white',
  textDecoration: 'none',
  fontSize: '16px',
};

function Header() {
  return (
    <header style={headerStyle}>
      <nav style={navStyle}>
        <h1 style={logoStyle}>VitalPath Innovations</h1>
        <ul style={ulStyle}>
          <li style={liStyle}><Link to="/" style={linkStyle}>Home</Link></li>
          <li style={liStyle}><Link to="/overview" style={linkStyle}>Overview</Link></li>
          <li style={liStyle}><Link to="/investors-partners" style={linkStyle}>Investors & Partners</Link></li>
          <li style={liStyle}><Link to="/technology" style={linkStyle}>Technology</Link></li>
          <li style={liStyle}><Link to="/applications" style={linkStyle}>Applications</Link></li>
          <li style={liStyle}><Link to="/contact" style={linkStyle}>Contact</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;