import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const OverviewPage = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const sections = [
    {
      title: "Our Technology",
      content: `At the heart of VitalPath Solutions is our proprietary AI-driven platform. We combine
                advanced machine learning algorithms with natural language processing to create a
                uniquely responsive and adaptive health coaching experience. Our system continuously
                learns from each interaction, ensuring that the guidance provided is always relevant
                and personalized.`,
      image: "/images/ai-technology.jpg"
    },
    {
      title: "Development Process",
      content: `Our iterative development process involves close collaboration with healthcare
                professionals, behavioral psychologists, and end-users. This multidisciplinary
                approach ensures that our AI health coach not only provides accurate medical
                information but also communicates in a way that motivates and engages users
                for long-term health improvements.`,
      image: "/images/development-process.jpg"
    },
    {
      title: "Security and Compliance",
      content: `We prioritize the security and privacy of user data. Our platform is built with
                HIPAA compliance at its core, utilizing end-to-end encryption and rigorous
                access controls. We regularly undergo third-party security audits to ensure
                that we're always at the forefront of data protection in healthcare technology.`,
      image: "/images/security-compliance.jpg"
    },
    {
      title: "Our Team",
      content: `Our team is led by visionary experts in AI, health informatics, and behavioral science. 
                Together, we're revolutionizing chronic disease management with cutting-edge technology 
                and a deep understanding of human behavior.`,
      image: "/images/our-team.jpg",
      team: [
        {
          name: "John-Eric Bonilla, P.M.P.",
          title: "Founder and Principal Investigator",
          bio: `John-Eric brings over a decade of experience in data science, project management, and health informatics to VitalPath Innovations. As a Ph.D. candidate in Information Science, he leads our research and development efforts, ensuring the seamless integration of Motivational Interviewing principles into our AI-driven solutions.`,
          image: "/images/john-eric-bonilla.jpg"
        },
        {
          name: "Dr. Xiaowei Xu",
          title: "Board of Advisors",
          bio: `Dr. Xu is a renowned expert in AI and machine learning, recognized for developing the DBSCAN clustering algorithm. His expertise in causal AI and transfer learning significantly contributes to the advancement of our AI-powered health coaching platform.`,
          image: "/images/xiaowei-xu.jpg"
        },
        {
          name: "Dr. Ningning Wu",
          title: "Board of Advisors",
          bio: `With over 15 years of experience in data mining and information quality, Dr. Wu brings crucial insights to ensure the consistency and accuracy of our platform's data. Her expertise helps us maintain the highest standards of data management and security.`,
          image: "/images/ningning-wu.jpg"
        },
        {
          name: "Dr. Ahmed Abu-Halimeh",
          title: "Board of Advisors",
          bio: `Dr. Abu-Halimeh's expertise in health informatics and machine learning is instrumental in enhancing our data curation and quality processes. His experience in both academia and industry helps bridge the gap between theoretical research and practical application.`,
          image: "/images/ahmed-abu-halimeh.jpg"
        },
        {
          name: "Dr. Daniel Berleant",
          title: "Board of Advisors",
          bio: `Dr. Berleant's extensive background in artificial intelligence, bioinformatics, and text mining enriches our platform's capabilities. His interdisciplinary approach helps us push the boundaries of what's possible in AI-driven health coaching.`,
          image: "/images/daniel-berleant.jpg"
        }
      ]
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.h2
        className="text-3xl font-bold mb-8 text-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Inside VitalPath Solutions
      </motion.h2>
     
      {sections.map((section, index) => (
        <motion.section
          key={index}
          className="mb-8 bg-white rounded-lg shadow-md overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.2 }}
        >
          <motion.div
            className="flex items-center p-4 cursor-pointer"
            onClick={() => setExpandedSection(expandedSection === index ? null : index)}
            whileHover={{ backgroundColor: "#f0f0f0" }}
          >
            <img
              src={section.image}
              alt={section.title}
              className="w-24 h-24 object-cover rounded-full mr-4"
            />
            <h3 className="text-2xl font-semibold">{section.title}</h3>
          </motion.div>
         
          <AnimatePresence>
            {expandedSection === index && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="px-4 pb-4"
              >
                <p className="mb-4 text-gray-700 leading-relaxed">{section.content}</p>
                {section.team && (
                  <div className="grid md:grid-cols-2 gap-4 mt-6">
                    {section.team.map((member, memberIndex) => (
                      <div key={memberIndex} className="flex items-start space-x-4">
                        <img
                          src={member.image}
                          alt={member.name}
                          className="w-20 h-20 object-cover rounded-full"
                        />
                        <div>
                          <h4 className="text-lg font-semibold">{member.name}</h4>
                          <p className="text-sm text-gray-600 mb-2">{member.title}</p>
                          <p className="text-sm text-gray-700">{member.bio}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.section>
      ))}
    </div>
  );
}

export default OverviewPage;