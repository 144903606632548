import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ContactPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const response = await fetch("https://formspree.io/f/mldryepp", {
        method: "POST",
        body: new FormData(e.target),
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setSubmitStatus("success");
        e.target.reset();
      } else {
        setSubmitStatus("error");
      }
    } catch (error) {
      setSubmitStatus("error");
    }

    setIsSubmitting(false);
  };

  const interestOptions = [
    'Investor',
    'Healthcare Provider',
    'Insurance Company',
    'Wellness Program Provider',
    'Tech Innovator',
    'Job Seeker',
    'Other'
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.h2 
        className="text-3xl font-bold mb-4 text-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Join the Health Coaching Revolution
      </motion.h2>
      <motion.p 
        className="mb-6 text-center max-w-2xl mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        We're seeking forward-thinking partners, investors, and collaborators who share our vision of a healthier world. Whether you're a healthcare provider, a tech innovator, or an investor with a passion for transformative healthcare solutions, we want to hear from you.
      </motion.p>
      <motion.form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <div className="mb-4">
          <label htmlFor="name" className="block mb-2 font-semibold">Name</label>
          <input 
            type="text" 
            name="name" 
            id="name" 
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500" 
            required 
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 font-semibold">Email</label>
          <input 
            type="email" 
            name="email" 
            id="email" 
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500" 
            required 
          />
        </div>
        <div className="mb-4">
          <label htmlFor="organization" className="block mb-2 font-semibold">Organization</label>
          <input 
            type="text" 
            name="organization" 
            id="organization" 
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500" 
          />
        </div>
        <div className="mb-4">
          <label htmlFor="interest" className="block mb-2 font-semibold">Area of Interest</label>
          <select
            name="interest"
            id="interest"
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Select an option</option>
            {interestOptions.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block mb-2 font-semibold">Message</label>
          <textarea 
            name="message" 
            id="message" 
            rows="4" 
            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500" 
            required
          ></textarea>
        </div>
        <motion.button 
          type="submit" 
          className="w-full bg-blue-600 text-white px-6 py-3 rounded font-semibold hover:bg-blue-700 transition duration-300"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </motion.button>
        {submitStatus === "success" && (
          <motion.p 
            className="mt-4 text-green-600 text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            Thank you for your message. We're excited about the potential collaboration and will get back to you soon!
          </motion.p>
        )}
        {submitStatus === "error" && (
          <motion.p 
            className="mt-4 text-red-600 text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            Oops! There was a problem sending your message. Please try again or contact us directly.
          </motion.p>
        )}
      </motion.form>
      <motion.p
        className="mt-8 text-center text-sm text-gray-600"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        By reaching out, you're taking the first step towards reshaping the future of health. We look forward to exploring how we can work together to make a difference.
      </motion.p>
    </div>
  );
}

export default ContactPage;
