import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const TechnologyPage = () => {
  const [activeTab, setActiveTab] = useState('ai');

  const tabContent = {
    ai: {
      title: "AI-Powered Health Coaching",
      content: "Our platform harnesses the power of artificial intelligence and cloud computing to deliver personalized, empathetic health coaching at an unprecedented scale. By focusing on individual motivations and behaviors, we guide users through tailored health plans designed to improve long-term outcomes.",
      features: [
        "Unparalleled personalization",
        "Adaptive learning algorithms",
        "Natural language processing for empathetic communication",
        "Predictive health modeling",
        "Real-time adjustments based on user feedback"
      ]
    },
    features: {
      title: "Key Platform Features",
      content: "VitalPath Solutions offers a comprehensive suite of features designed to revolutionize chronic disease management and prevention. Our AI-driven platform empowers users to build lives aligned with their personal values and priorities, fostering lasting behavioral change.",
      features: [
        "Value-Centric Guidance: We help clients identify and prioritize their personal values, allowing them to shape their health journey in a way that's meaningful to them.",
        "Client-Driven Goal Setting: Users choose who they want to be, setting goals that align with their vision for themselves.",
        "AI-Powered Personalization: Our machine learning algorithms adapt to each user's unique values, priorities, and progress.",
        "Motivational Interviewing Techniques: We employ AI-driven conversational strategies to help users discover their own motivations for change.",
        "Long-Term Engagement: Our approach fosters intrinsic motivation, keeping users engaged in their health journey over time.",
        "Scalable Support: From 100 to 1 million users, we provide personalized guidance without compromising quality.",
        "Continuous Platform Evolution: Our AI continuously learns and improves, refining its ability to support users effectively.",
        "Secure, HIPAA-Compliant Environment: We prioritize user privacy and data security in all our operations."
      ]
    },
    security: {
      title: "Data Security and Compliance",
      content: "We prioritize the security and privacy of user data, ensuring full compliance with HIPAA regulations. Our robust security measures protect sensitive health information, providing peace of mind for users, healthcare partners, and investors alike.",
      features: [
        "End-to-end encryption for all data transmissions",
        "Regular third-party security audits",
        "Strict HIPAA compliance measures",
        "Secure cloud-based data storage",
        "Granular user data control and access management"
      ]
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.h2
        className="text-3xl font-bold mb-8 text-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Cutting-Edge Technology for Better Health
      </motion.h2>
     
      <div className="flex mb-4 justify-center">
        {Object.keys(tabContent).map((tab) => (
          <motion.button
            key={tab}
            className={`mx-2 px-4 py-2 rounded ${activeTab === tab ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setActiveTab(tab)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {tab === 'ai' ? 'AI Innovation' : tab === 'features' ? 'Key Features' : 'Data Security'}
          </motion.button>
        ))}
      </div>

      <AnimatePresence mode="wait">
        <motion.section
          key={activeTab}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-lg shadow-md p-6"
        >
          <h3 className="text-2xl font-semibold mb-4">{tabContent[activeTab].title}</h3>
          <p className="mb-6">{tabContent[activeTab].content}</p>
         
          <h4 className="text-xl font-semibold mb-2">Key Aspects:</h4>
          <ul className="list-disc pl-5">
            {tabContent[activeTab].features.map((feature, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                {feature}
              </motion.li>
            ))}
          </ul>
        </motion.section>
      </AnimatePresence>
    </div>
  );
}

export default TechnologyPage;